import React from 'react';
import {
    Login,
    LoginForm
} from 'react-admin';

const MyLoginPage = (props) => {

    console.log('props');
    console.log(props);
    const loginForm = <>
        <LoginForm />
    </>;
    return <Login
        children={loginForm}

    />;
};

export default MyLoginPage;
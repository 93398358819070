import React from 'react';
import {
    ChipField,
    Filter,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import DistributorsExport from '../actions/DistributorsExport';

class DistributorList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'logo' || fieldName === 'qrCode' || fieldName === 'contactNameCN' || fieldName === 'contactNameEN' || fieldName === 'contactCN' || fieldName === 'contactEN') {
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <TextField label="Logo" />
                <ImageFromAPIField source="logo" field="uri" folder="distributor-logo" height="100"/>
                <TextField label="QR Code" />
                <ImageFromAPIField source="qrCode" field="uri" folder="distributor-qr-code" height="100"/>

                <ReferenceManyField label={'Wines'} reference={'wines'} target={'distributors'}>
                    <SingleFieldList>
                        <ChipField source='nameEN' />
                    </SingleFieldList>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <DistributorsExport {...props}/>
        </TopToolbar>)
    }
}

export default DistributorList;

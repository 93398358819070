import React from 'react';
import {
    ArrayField,
    ChipField,
    Filter,
    FunctionField,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import ProducersExport from '../actions/ProducersExport';

class ProducerList extends CustomTableList {

    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'logo' || fieldName === 'images'|| fieldName === 'contactNameCN' || fieldName === 'contactNameEN') {
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <TextField label="Logo" />
                <ImageFromAPIField source="logo" field="uri" folder="producer-logo" height="100"/>
                <ArrayField source="images">
                    <SingleFieldList>
                        <FunctionField render={(record) =>
                            <ImageFromAPIField {...props} record={{ ...record }} title= "Image" field="uri" folder="producer-image" height="100" />
                        } />
                    </SingleFieldList>
                </ArrayField>
                <ReferenceManyField label={'Wines'} reference={'wines'} target={'producers'}>
                    <SingleFieldList>
                        <ChipField source='nameEN' />
                    </SingleFieldList>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>;
    }

    customActions = (props) => {
        return (<TopToolbar>
            <ProducersExport {...props}/>
        </TopToolbar>)
    }
}

export default ProducerList;

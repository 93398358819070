import React from 'react';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';
import BatchUploadField from '../fields/BatchUploadField';

class ProducerCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'logo') {
            fieldList.push(<MyInputFile source={fieldName} label={'Logo'} folder={'producer-logo'} />);
            return true;
        } else if (fieldName === 'images') {
            fieldList.push(<BatchUploadField source={fieldName} label="Images" folder="producer-image"/>);
            return true;
        } else if (fieldName === 'clicksEmail' || fieldName === 'clicksWechat') {
            return true;
        }
        return false;
    }
}

export default ProducerCreate;